import React, { useState, useEffect, useCallback } from 'react';
import { getRecords, saveRecord, searchRecord } from '@app/utils/APIUtils';
import { Modal, Tag, Row, Descriptions, Badge } from 'antd';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash.debounce';
import PDFDownloader from '@app/components/pdf-downloader/PDFDownloader';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const CustomerWorkshopList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [recordSet, setRecordSet] = useState([]);
  const [searchedText, setsearchedText] = useState('');
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewRecord, setViewRecord] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { workshopTitle } = location.state || {};

  const loadRecords = useCallback(
    debounce((searchText) => {
      setLoading(true);
      setRecordSet([]);
      getRecords(`/public/customer/v1/workshops/${id}?searchedText=${encodeURIComponent(searchText)}`)
        .then((response) => {
          setRecordSet(response);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  useEffect(() => {
    loadRecords(searchedText);
  }, [searchedText, loadRecords]);

  const handleSearch = (e) => {
    setsearchedText(e.target.value);
  };

  const addAsCustomer = (values) => {
    const searchRequest = {
      customerName: values.name === '',
      mobileNumber: values.mobile === '' ? null : values.mobile,
      groupNumber: values.mobile === '',
    };
    const saveRequest = {
      customerName: values.name,
      mobileNumber: values.mobile,
      email: values.email,
      addressLineOne: values.address,
      remarks: values.remarks,
    }; // Clone record

    searchRecord(searchRequest, '/public/v1/customers/search')
      .then((response) => {
        if (response.length !== 0) {
          // Show modal with only names
          Modal.confirm({
            title: `Similar Customers Found With Mobile Number: ${values.mobile}`,
            content: (
              <div>
                <p><b>Existing Customers:</b></p>
                <ul>
                  {response.map((item, index) => (
                    <li key={index}>{item.customerName}</li>
                  ))}
                </ul>
                <p>Do you still want to save?</p>
              </div>
            ),
            okText: "Yes, Save",
            cancelText: "No, Cancel",
            onOk: () => {
              // Proceed with saving
              forceSaveCustomer(saveRequest);
            },
          });
        } else {
          // Proceed with saving
          forceSaveCustomer(saveRequest);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  const forceSaveCustomer = (saveRequest) => {
    saveRecord(saveRequest, '/public/v1/customers/workshop?force=true') // Use a different endpoint or flag
      .then(() => {
        notificationController.success({
          message: 'Customer created.',
        });
        navigate('/workshop/list');
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  const handleOk = () => setVisible(false);
  const handleCancel = () => setVisible(false);

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', width: '15%' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Mode',
      dataIndex: 'registrationMode',
      key: 'registrationMode',
      render: (_, { registrationMode }) => {
        const color = registrationMode === 1 ? '#87d068' : '#f50';
        const label = registrationMode === 1 ? 'Online' : 'Offline';
        return (
          <Tag color={color} key={registrationMode}>
            {label}
          </Tag>
        );
      },
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      key: 'paymentMode',
      render: (_, { paymentMode }) => {
        const color = '#108ee9';
        const label = paymentMode === 1 ? 'bKash' : paymentMode === 2 ? 'Rocket' : 'Scholarship';
        return (
          <Tag color={color} key={paymentMode}>
            {label}
          </Tag>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      render: (_, { paymentAmount }) => {
        const color = '#2db7f5';
        return (
          <Tag color={color} key={paymentAmount}>
            {paymentAmount}
          </Tag>
        );
      },
    },
    { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => addAsCustomer(record)} loading={loading}>
            Add as Customer
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  
  //Download records as PDF section
  // Define title and sub-title
  const leftTitle = 'HAJLINK.COM' || ''; // Fallback if HAJLINK.COM is empty
  const subTitle = `${workshopTitle}`;
  const rightTitle = 'Printed on:';
  //Pdf report column
  const reportColumns = [
    'SL',
    'Name',
    'Mobile',
    'Email',
    'Mode',
    'Payment Mode',
    'Amount',
    'Remarks',
  ];

  //Map pdfRecords
  const pdfRecords = recordSet.map((data, index) => [
    index + 1,
    data.name,
    data.mobile,
    data.email,
    data.registrationMode,
    data.paymentMode,
    data.paymentAmount,
    data.remarks,
  ]);

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title={`Customer List on Workshop: ${workshopTitle}`}
        padding="1.25rem 1.25rem 0"
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={handleSearch}
              value={searchedText}
              allowClear
              size="small"
              style={{ width: 230, marginBottom: 10 }}
            />
          </ST.InputsWrapper>
          <div className="importBtn">
            <BaseSpace>
              <PDFDownloader
                leftTitle={leftTitle}
                rightTitle={rightTitle}
                subTitle={subTitle}
                pdfRecordSet={pdfRecords}
                reportColumns={reportColumns}
                setTableLoading={setLoading} // Pass loading state
              />
            </BaseSpace>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={recordSet}
          rowKey={(record) => record.id}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal open={visible} onOk={handleOk} onCancel={handleCancel} footer={null} width={1000} style={{ top: 20 }}>
        <Row>
          <Descriptions title="Customer Info" layout="horizontal" bordered size="middle">
            <Descriptions.Item label="CID">{viewRecord.customerId}</Descriptions.Item>
            <Descriptions.Item label="Name">{viewRecord.customerName}</Descriptions.Item>
            <Descriptions.Item label="Profession">{viewRecord.profession}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{viewRecord.mobileNumber}</Descriptions.Item>
            <Descriptions.Item label="WhatsApp">{viewRecord.whatsAppNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{viewRecord.email}</Descriptions.Item>
            <Descriptions.Item label="District">{viewRecord.district}</Descriptions.Item>
            <Descriptions.Item label="Thana">{viewRecord.thana}</Descriptions.Item>
            <Descriptions.Item label="Address">{viewRecord.addressLineOne}</Descriptions.Item>
            <Descriptions.Item label="NID">{viewRecord.nid}</Descriptions.Item>
            <Descriptions.Item label="DOB">{viewRecord.dob}</Descriptions.Item>
            <Descriptions.Item label="Father's Name">{viewRecord.fatherName}</Descriptions.Item>
            <Descriptions.Item label="Mother's Name">{viewRecord.motherName}</Descriptions.Item>
            <Descriptions.Item label="Spouse Name">{viewRecord.spouseName}</Descriptions.Item>
            <Descriptions.Item label="Gender">{viewRecord.gender === 1 ? 'Male' : 'Female'}</Descriptions.Item>
            <Descriptions.Item label="Group Name">{viewRecord.groupName}</Descriptions.Item>
            <Descriptions.Item label="Group No">{viewRecord.groupNumber}</Descriptions.Item>
            <Descriptions.Item label="Remarks">{viewRecord.remarks}</Descriptions.Item>
            <Descriptions.Item label="Reference">{viewRecord.reference}</Descriptions.Item>
            <Descriptions.Item label="Status Change Notes">{viewRecord.statusChangeNotes}</Descriptions.Item>
            <Descriptions.Item label="Status">{viewRecord.customerStatus}</Descriptions.Item>
            <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated By">{viewRecord.updatedBy}</Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {moment(viewRecord.updatedDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Activated">
              <Badge
                status={viewRecord.isActive === 1 ? 'success' : 'error'}
                text={viewRecord.isActive === 1 ? 'Yes' : 'No'}
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Modal>
    </div>
  );
};

export default CustomerWorkshopList;
