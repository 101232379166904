import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { login } from 'utils/APIUtils';
import { ACCESS_TOKEN } from '@app/constants/index';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { EyeFilled, UserOutlined, InteractionOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import { useTranslation } from 'react-i18next';
import * as SL from '@app/components/layouts/main/sider/MainSider/MainSider.styles';
import AppLogo from 'assets/hajj_link_logo.png';
import { getCaptcha } from 'utils/APIUtils';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const LoginForm = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [captchaObj, setCaptchaObj] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    reloadCaptcha();
  }, []);

  //Reload captcha
  const reloadCaptcha = () => {
    getCaptcha('/auth/captcha')
      .then((res) => {
        setCaptchaObj(res);
        form.setFieldsValue({
          captcha: res.captcha,
          realCaptcha: res.realCaptcha,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  //Handle form submit
  const handleSubmit = (values) => {
    setLoading(true);
    setTimeout(() => {
      const loginRequest = new FormData();
      loginRequest.append('username', values.username);
      loginRequest.append('password', values.password);

      if (values.captcha === captchaObj.hiddenCaptcha) {
        login(loginRequest)
          .then((response) => {
            if (response.statusCode === 200) {
              localStorage.setItem(ACCESS_TOKEN, response.accessToken);
              props.onLogin();
              navigate('/dashboard');
            }
            setLoading(false);
          })
          .catch((error) => {
            notificationController.error({
              message: error.message,
            });
            setLoading(false);
          });
      } else {
        notificationController.error({
          message: 'Please input valid captcha!',
        });
        setLoading(false);
      }
    }, 1000); // Adds a 1-second delay
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" form={form} onFinish={handleSubmit}>
        <SL.SiderLogoLink to="/" style={{ justifyContent: 'center' }}>
          <img src={AppLogo} alt="HAJLINK LOGO" height={40} />
        </SL.SiderLogoLink>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>

        <Auth.FormItem
          name="username"
          label={t('common.username')}
          rules={[
            {
              required: true,
              message: t('common.nameRequiredField'),
            },
          ]}
          onKeyDown={(e) => handleEnter(e)}
        >
          <Auth.FormInput prefix={<UserOutlined />} placeholder={t('common.username')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[
            {
              required: true,
              message: t('common.passwordRequiredField'),
            },
          ]}
          onKeyDown={(e) => handleEnter(e)}
        >
          <Auth.FormInputPassword prefix={<EyeFilled />} placeholder={t('common.password')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="captcha"
          label="Captcha"
          rules={[
            {
              required: true,
              message: 'Please input captcha!',
            },
          ]}
          onKeyDown={(e) => handleEnter(e)}
        >
          <Auth.FormInput prefix={<InteractionOutlined />} placeholder="Captcha" />
        </Auth.FormItem>

        <Auth.ActionsWrapper>
          <Auth.FormItem>
            {captchaObj.realCaptcha && (
              <img width="240" height="70" src={`data:image/png;base64,${captchaObj.realCaptcha}`} alt="captcha" />
            )}
          </Auth.FormItem>
          <a onClick={reloadCaptcha} style={{ color: '#1c68a6', marginTop: 20, fontWeight: 600 }}>
            Try Another
          </a>
        </Auth.ActionsWrapper>

        <Auth.ActionsWrapper>
          <Form.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </Form.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>

        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};

export default LoginForm;
