import React, { Component, useState } from 'react';
import { searchRecord, getRecords, getRecordById, saveRecord } from '@app/utils/APIUtils';
import { Form, Input, Select, Descriptions, Tag } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return <AddPayment {...props} navigation={navigate} loading={loading} setLoading={setLoading} form={form} />;
}

class AddPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isReadonly: false,
      isRequired: false,
      serviceList: [],
      packageList: [],
      paymentModeList: [],
      recordSet: [],
      customerInfo: {},
      amountInfo: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    //this.handleServiceChange = this.handleServiceChange.bind(this);
    //this.clearForm = this.clearForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let /* promise, */ promise1;

    //promise = getRecords('/setup/v1/services/all');
    promise1 = getRecords('/domain/data/paymentmode/v1/all');

    /* promise
      .then((response) => {
        this.setState({
          serviceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
        });
        console.log(this.state.serviceList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            loading: false,
          });
      }); */

    promise1
      .then((response) => {
        this.setState({
          paymentModeList: response.map((item) => ({ value: item.id, label: item.paymentModeName })),
        });
      })
      .catch((error) => {
        this.props.setLoading(false);
        notificationController.error({
          message: error.message,
        });
      });

    Promise.all([/* promise, */ promise1]);
  }

  /* handleServiceChange = (value) => {
    try {
      getRecords(`/setup/v1/packages/${value}/service`)
        .then((response) => {
          this.props.form.resetFields(['packageId']);
          this.setState({
            packageList: response.map((item) => ({ value: item.id, label: item.PackageName })),
          });
        })
        .catch((error) => {
          if (error) this.setState({ packageList: [] });
          this.setState({
            loading: false,
          });
        });
    } catch (error) {}
  }; */

  /* handleSubmit(values) {
    this.props.setSpinning(true);
    const saveRequest = Object.assign({}, values);
    //saveRequest.referenceStatusId  = 111;
    console.log('saveRequest => ' + JSON.stringify(saveRequest));

    fetch(API_BASE_URL + '/customer/v1/payment', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
      },
      body: JSON.stringify(saveRequest),
    })
      .then(async (response) => {
        if (response.ok) {
           const reportBytes = await response.arrayBuffer();
          const blob = new File([new Uint8Array(reportBytes)], 'filename.pdf', { type: 'application/pdf' });
          const reportUrl = URL.createObjectURL(blob);
          window.open(reportUrl, 'test');
          notificationController.success({
            message: 'Payment successful.',
          });
          this.props.form.resetFields();
          setTimeout(() => {
            this.props.setSpinning(false);
          }, 1000);
        }
      })
      .catch((error) => {
        this.props.setSpinning(false);
        notificationController.error({
          message: error.message,
        });
      });
  } */

  handleSubmit(values) {
    this.props.setLoading(true);
    const saveRequest = { ...values };
    console.log('saveRequest =>', JSON.stringify(saveRequest));

    saveRecord(saveRequest, '/customer/v1/payment')
      .then((response) => {
        if (response.statusCode === 201) {
          this.successMessage = 'Payment received.'; // Store message to show later
        } else {
          throw new Error('Unexpected response code: ' + response.message);
        }
      })
      .catch((error) => {
        this.errorMessage = 'Failed! ' + error.message; // Store error message
      })
      .finally(() => {
        setTimeout(() => {
          this.props.setLoading(false);

          // Show notification after loading finishes
          if (this.successMessage) {
            notificationController.success({ message: this.successMessage });
            this.props.form.resetFields();
            this.successMessage = null; // Reset message
          }
          if (this.errorMessage) {
            notificationController.error({ message: this.errorMessage });
            this.errorMessage = null; // Reset message
          }
        }, 2000); // Delay for UI smoothness
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/payment/list');
  }

  handleSearch(values) {
    if (values.mobileNumber === undefined && values.groupNumber === undefined && values.customerName === undefined) {
      notificationController.warning({
        message: 'Please enter one filtering criteria!',
      });
    }
    const searchRequest = {
      customerName: values.customerName === '' ? null : values.customerName,
      mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
      groupNumber: values.groupNumber === '' ? null : values.groupNumber,
    };

    searchRecord(searchRequest, '/public/v1/customers/search')
      .then((response) => {
        this.setState({
          recordSet: response,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  handleRowClick = (record) => {
    this.setState({
      customerInfo: record,
    });

    this.props.form.setFieldsValue({
      ['customerId']: record.customerId,
      ['serviceId']: record.serviceId,
      ['packageId']: record.packageId,
    });

    let promise = getRecordById('/customer/v1/payment/calculate/amount/', record.customerId);
    promise
      .then((res) => {
        this.setState({
          amountInfo: res,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  render() {
    const { customerInfo, recordSet, amountInfo } = this.state;
    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);
    const data = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
        packageId: record.packageId,
        packageName: record.packageName,
        email: record.email,
        serviceId: record.serviceId,
        serviceName: record.serviceName,
        hotelName: record.hotelName,
        roomNumber: record.roomNumber,
        busNo: record.busNo,
        flightNo: record.flightNo,
        address: record.address,
      });
    });

    const columns = [
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group Number',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];

    return (
      <BaseCard id="validation form" title="Add Payment" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <SearchCustomer
              searchForm={this.props.form}
              onFinish={this.handleSearch}
              customerName="customerName"
              mobileNumber="mobileNumber"
              groupNumber="groupNumber"
              onKeyDown={(e) => handleEnter(e)}
            />
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="search" padding="1.25rem">
              <BaseRow gutter={[0, 0]}>
                <BaseCol xs={24} sm={24} xl={24}>
                  <BaseTable
                    onRow={(record) => ({
                      onClick: () => this.handleRowClick(record), // Handle row click
                    })}
                    columns={columns}
                    dataSource={data}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey={(record) => record.customerId}
                    bordered
                  />
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="search" padding="1.25rem">
              <Descriptions title="Customer Details" bordered size="middle" layout="vertical" column={2}>
                <Descriptions.Item label="Customer Name" span={1}>
                  {customerInfo.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile Number" span={1}>
                  {customerInfo.mobileNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={1}>
                  {customerInfo.email}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={1}>
                  {customerInfo.address}
                </Descriptions.Item>
                <Descriptions.Item label="Group" span={1}>
                  {customerInfo.groupNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Package" span={1}>
                  {customerInfo.packageName}
                </Descriptions.Item>
                <Descriptions.Item label="Hotel" span={1}>
                  {customerInfo.hotelName}
                </Descriptions.Item>
                <Descriptions.Item label="Room" span={1}>
                  {customerInfo.roomNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Bus" span={1}>
                  {customerInfo.busNo}
                </Descriptions.Item>
                <Descriptions.Item label="Flight" span={1}>
                  {customerInfo.flightNo}
                </Descriptions.Item>
                <Descriptions.Item label="Total Payable Amount" span={1}>
                  <Tag color="blue" key={amountInfo.pkgPrice} style={{ fontSize: 15, fontWeight: 600 }}>
                    {'৳'.concat(' ').concat(numberFormat(amountInfo.pkgPrice || 0))}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Total Paid Amount" span={1}>
                  <Tag color="green" key={amountInfo.paidAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                    {'৳'.concat(' ').concat(numberFormat(amountInfo.paidAmount || 0))}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Total Due Amount" span={1}>
                  <Tag color="red" key={amountInfo.dueAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                    {'৳'.concat(' ').concat(numberFormat(amountInfo.dueAmount || 0))}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
            </BaseCard>
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="payment-form"
              name="paymentForm"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500, marginTop: '20px' }}
              initialvalues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Customer Id" name="customerId" hidden={true} className="ant-latest-form-item">
                {/* <-- Pass hidden customerId --> */}
                <Input
                  size="small"
                  placeholder="Enter customer id"
                  readOnly={true}
                  style={{ backgroundColor: '#c5d3e0' }}
                />
              </FormItem>

              <FormItem label="Service" name="serviceId" hidden={true} className="ant-latest-form-item">
                {/* <-- Pass hidden serviceId --> */}
                <Input size="small" placeholder="Enter service id" readOnly={true} />
              </FormItem>

              <FormItem label="Package" name="packageId" hidden={true} className="ant-latest-form-item">
                {/* <-- Pass hidden packageId --> */}
                <Input size="small" placeholder="Enter package id" readOnly={true} />
              </FormItem>

              <FormItem
                label="Voucher No"
                name="voucherNo"
                rules={[
                  {
                    required: true,
                    message: 'Voucher no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter voucher no" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              {/* <FormItem label="Service" hidden={true}>
                  {getFieldDecorator('serviceId', {
                    rules: [{ required: false, message: 'Service is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select Service"
                      size="small"
                      name="serviceId"
                      onChange={this.handleServiceChange}
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      {this.state.serviceList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="Package" hidden={true}>
                  {getFieldDecorator('packageId', {
                    rules: [{ required: false, message: 'Package is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select Package"
                      size="small"
                      name="packageId"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      {this.state.packageList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem> */}

              <FormItem
                label="Mode of Payment"
                name="paymentMode"
                rules={[
                  {
                    required: true,
                    message: 'Payment mode is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select payment mode"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.paymentModeList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Amount is required!',
                  },
                  {
                    pattern: /^-?\d*$/,
                    message: 'Only number allowed',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input addonAfter="BDT" size="small" placeholder="Enter amount" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Less / Adjustment"
                name="lessAdjustment"
                rules={[
                  {
                    required: false,
                    message: 'Less/Adjustment is required!',
                  },
                  {
                    pattern: /^-?\d*$/,
                    message: 'Only number allowed',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input
                  addonAfter="BDT"
                  size="small"
                  placeholder="Enter Less/Adjustment amount"
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              <FormItem label="Others" name="others" className="ant-latest-form-item">
                <Input placeholder="Enter others" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem label="Remarks" name="remarks" className="ant-latest-form-item">
                <Input size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} loading={this.props.loading} />
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}
