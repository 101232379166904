import React, { useEffect, useState } from 'react';
import { getRecordById, updateFormRecord } from '@app/utils/APIUtils';
import { Input, Select, DatePicker, Form} from 'antd';
import Moment from 'moment';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { MAX_FILE_SIZE } from '@app/constants';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';

const { TextArea } = Input;

const EditWorkshop = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [banner, setBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [bannerKey, setBannerKey] = useState(Date.now());
  const [previewBanner, setPreviewBanner] = useState(null);
  const [isPublished, setIsPublished] = useState(true);
  const [statusItems] = useState([
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [workshopDetails] = await Promise.all([
          getRecordById('/admin/v1/workshops/', id),
        ]);

        form.setFieldsValue({
          workshopTitle: workshopDetails.WorkshopTitle,
          shortDescription: workshopDetails.ShortDesc,
          workshopDetails: workshopDetails.WorkshopDetails,
          startDate: workshopDetails.StartDate === null ? null : Moment(workshopDetails.StartDate),
          endDate: workshopDetails.EndDate === null ? null : Moment(workshopDetails.EndDate),
          price: workshopDetails.Price || 0.0,
          remarks: workshopDetails.Remarks,
          isPublished: workshopDetails.IsPublished,
          recordStat: workshopDetails.status,
        });

        setIsPublished(workshopDetails.IsPublished);
        setPreviewBanner(workshopDetails.Banner ? `data:image/png;base64,${workshopDetails.Banner}` : null);
      } catch (error) {
        notificationController.error({
          message: `Failed to load data: ${error.message}`,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, form]);

  const handleChangeIsPublished = (e) => {
    setIsPublished(e.target.checked);
  };

  const onChangeDate = (date, dateString) => {
    console.log('Selected Date:', date, 'Formatted Date:', dateString);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onCancelForm = (event) => {
    event.preventDefault();
    navigate('/workshop/list');
  }

  const handleBannerChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // Reset the input field by changing its key (force re-render)
      setBannerKey(Date.now());
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
      return;
    }
    const fileUrl = URL.createObjectURL(uploadedFile);
    setPreviewBanner(fileUrl);
    setBanner(uploadedFile);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("banner", banner);
    formData.append("workshopTitle", values.workshopTitle);
    formData.append("shortDescription", values.shortDescription);
    formData.append("workshopDetails", values.workshopDetails);
    formData.append('startDate', values.startDate === null ? '' : Moment(values.startDate).format('YYYY-MM-DD'));
    formData.append('endDate', values.endDate === null ? '' : Moment(values.endDate).format('YYYY-MM-DD'));
    formData.append('price', values.price !== 0.0 ? values.price : 0.0);
    formData.append('remarks', values.remarks == null ? '' : values.remarks);
    formData.append('isPublished', isPublished);
    formData.append('recordStat', values.recordStat);

    try {
      await updateFormRecord(id, formData, "/admin/v1/workshops/");
      notificationController.success({ message: "Workshop updated!" });
      form.resetFields();
      setBanner(null);
      setPreviewBanner(null);
      navigate('/workshop/list');
    } catch (error) {
      notificationController.error({ message: `Error update workshop: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseCol xs={24} sm={24} xl={24}>
      <BaseCard id="workshopForm" title="Add Workshop" padding="1.25rem">
        <Form
          form={form}
          onFinish={handleSubmit}
          name="basic"
          labelCol={{ span: 7 }}
          style={{ maxWidth: 500 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            name="workshopTitle"
            label="Workshop Title"
            rules={[{ required: true, message: "Workshop title is required." }]}
          >
            <Input
              placeholder="Enter workshop title"
              size="small"
              onKeyDown={(e) => handleEnter(e)}
            />
          </Form.Item>

          <Form.Item
            name="shortDescription"
            label="Short Description"
            rules={[{ required: true, message: "Short description is required." }]}
          >
            <TextArea
              rows={2}
              placeholder="Enter short description"
              onKeyDownCapture={(e) => {
                if (e.keyCode === 78 && e.altKey) {
                  e.preventDefault();
                  e.target.value += '\r\n';
                }
              }}
              onKeyDown={(e) => handleEnter(e)}
            />
          </Form.Item>

          <Form.Item
            name="workshopDetails"
            label="Workshop Details"
            rules={[{ required: true, message: "Workshop details is required." }]}
            className="ant-latest-form-item"
          >
            <TextArea
              rows={2}
              placeholder="Enter workshop details"
              onKeyDownCapture={(e) => {
                if (e.keyCode === 78 && e.altKey) {
                  e.preventDefault();
                  e.target.value += '\r\n';
                }
              }}
              onKeyDown={(e) => handleEnter(e)}
            />
          </Form.Item>

          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: false, message: 'Start date is required!' }]}

            className="ant-latest-form-item"
          >
            <DatePicker
              size="small"
              placeholder="Select Start Date"
              format={startDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
              onOpenChange={(status) => {
                setStartDateOpen(status);
              }}
              onChange={onChangeDate}
              onKeyDown={(e) => handleEnter(e)}
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="endDate"
            rules={[{ required: false, message: 'End date is required!' }]}
            className="ant-latest-form-item"
          >
            <DatePicker
              size="small"
              placeholder="Select End Date"
              format={endDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
              onOpenChange={(status) => {
                setEndDateOpen(status);
              }}
              onChange={onChangeDate}
              onKeyDown={(e) => handleEnter(e)}
            />
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: false, message: "Price is required." }]}
            className="ant-latest-form-item"
          >
            <Input placeholder="Enter workshop price" size="small" addonBefore="BDT" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item
            label="Banner"
            name="banner"
            rules={[{ required: false, message: 'Please choose a banner!' }]}
            className="ant-latest-form-item"
          >
            <Input
              type="file"
              accept="image/*"
              size="small"
              onKeyDown={(e) => handleEnter(e)}
              onChange={handleBannerChange}
              key={bannerKey} // Key is updated to force re-render
            />

            {previewBanner && (
              <div style={{ marginTop: 10 }}>
                <img src={previewBanner} alt="Banner Preview" style={{ height: 150, width: 355 }} />
              </div>
            )}
          </Form.Item>

          <Form.Item
            name="remarks"
            label="Remarks"
            rules={[{ required: false, message: "Remarks is required." }]}
            className="ant-latest-form-item"
          >
            <Input placeholder="Enter remarks" size="small" onKeyDown={(e) => handleEnter(e)} />
          </Form.Item>

          <Form.Item label="Published" name="isPublished" className="ant-latest-form-item">
            <BaseCheckbox onChange={handleChangeIsPublished} checked={isPublished} onKeyDown={handleEnter} />
          </Form.Item>

          <Form.Item
            label="Status"
            name="recordStat"
            rules={[{ required: true, message: 'Please select status!' }]}
            className="ant-latest-form-item"
          >
            <Select
              showSearch
              placeholder="Select"
              size="small"
              optionFilterProp="children"
              onSearch={onSearch}
              onKeyDown={handleEnter}
            >
              {statusItems.map((item, index) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 7,
              span: 9,
            }}
          >
            <BaseButtonsGroup onCancel={onCancelForm} loading={isLoading} />
          </Form.Item>
        </Form>
      </BaseCard>
    </BaseCol>
  );
};

export default EditWorkshop;
