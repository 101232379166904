import React, { useState, useEffect, useCallback } from 'react';
import { getRecordById, getRecordSet } from '@app/utils/APIUtils';
import { Modal, Tag, Row, Descriptions, Badge, Col } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import debounce from 'lodash.debounce';

const WorkshopList = () => {
  const [loading, setLoading] = useState(false);
  const [recordSet, setRecordSet] = useState([]);
  const [searchedText, setsearchedText] = useState('');
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState({});
  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchText, page, size = LIST_SIZE) => {
      setLoading(true);
      setRecordSet([]);
      getRecordSet(page, size, '/admin/v1/workshops?page=', searchText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  useEffect(() => {
    loadRecords(searchedText, page);
  }, [searchedText, page, loadRecords]);

  const handleSearch = (e) => {
    setsearchedText(e.target.value);
  };

  const save = () => {
    navigate('/workshop/new');
  };

  const navigateToCustomerWorkshop = (record) => {
    const workshopId = record.workshopId;
    navigate(`/workshop/customer/list/${workshopId}`, { state: { workshopTitle: record.workshopTitle } });
  };
  
  const edit = (id) => {
    navigate(`/workshop/edit/${id}`);
  };

  const view = (workshopId) => {
    setVisible(true);
    getRecordById('/admin/v1/workshops/', workshopId).then((res) => {
      setViewRecord(res);
    });
  };

  const handleOk = () => setVisible(false);
  const handleCancel = () => setVisible(false);

  const columns = [
    { title: 'Title', dataIndex: 'workshopTitle', key: 'workshopTitle' },
    { title: 'Start Date', dataIndex: 'startDate', key: 'startDate' },
    { title: 'End Date', dataIndex: 'endDate', key: 'endDate' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
    {
      title: 'Status',
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (_, { isPublished }) => {
        const color = isPublished ? '#108ee9' : '#f50';
        const label = isPublished ? 'Published' : 'Unpublished';
        return (
          <Tag color={color} key={isPublished}>
            {label}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.workshopId)}>
            View
          </BaseButton>
          <BaseButton size="small" type="ghost" onClick={() => navigateToCustomerWorkshop(record)}>
            Details
          </BaseButton>
          <BaseButton severity="info" size="small" onClick={() => edit(record.workshopId)}>
            Edit
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Workshop List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <Row gutter={[16, 16]} align="middle" className="search-row">
          {/* Search Input */}
          <Col xs={16} sm={16} md={18} lg={5}>
            <SearchInput
              placeholder="Search..."
              onChange={handleSearch}
              value={searchedText}
              allowClear
              size="small"
              style={{ width: '100%', marginBottom: 10 }}
            />
          </Col>
        </Row>
        <BaseTable
          columns={columns}
          dataSource={recordSet}
          pagination={{
            pageSize: 10,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
          }}
          rowKey={(record) => record.workshopId}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal open={visible} onOk={handleOk} onCancel={handleCancel} footer={null} width={1000} style={{ top: 20 }}>
        <Row>
          <Descriptions title="Customer Info" layout="vertical" bordered size="middle">
            <Descriptions.Item label="Workshop ID">{viewRecord.id}</Descriptions.Item>
            <Descriptions.Item label="Workshop Title">{viewRecord.WorkshopTitle}</Descriptions.Item>
            <Descriptions.Item label="Short Desc">{viewRecord.ShortDesc}</Descriptions.Item>
            <Descriptions.Item label="Workshop Details" span={3}>{viewRecord.WorkshopDetails}</Descriptions.Item>
            <Descriptions.Item label="Start Date">{viewRecord.StartDate}</Descriptions.Item>
            <Descriptions.Item label="End Date">{viewRecord.EndDate}</Descriptions.Item>
            <Descriptions.Item label="Price">{viewRecord.Price}</Descriptions.Item>
            <Descriptions.Item label="Remarks" span={2}>{viewRecord.Remarks}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Badge
                status={viewRecord.status === 1 ? 'success' : 'error'}
                text={viewRecord.status === 1 ? 'Active' : 'Inactive'}
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Modal>
    </div>
  );
};

export default WorkshopList;
