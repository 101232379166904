import React from 'react';
import {
  //CompassOutlined,
  DashboardOutlined,
  //FormOutlined,
  //HomeOutlined,
  //LayoutOutlined,
  //LineChartOutlined,
  //TableOutlined,
  //UserOutlined,
  //BlockOutlined,
  //CustomerServiceOutlined,
  SolutionOutlined,
  AppstoreAddOutlined,
  //DeploymentUnitOutlined,
  //CustomerServiceFilled,
  //CustomerServiceTwoTone,
  TeamOutlined,
  //FileSyncOutlined,
  //AuditOutlined,
  //ClusterOutlined,
  //MonitorOutlined,
  //MoneyCollectOutlined,
  //BranchesOutlined,
  //InsertRowBelowOutlined,
  //PushpinOutlined,
  FileDoneOutlined,
  //SisternodeOutlined,
  ApartmentOutlined,
  BankOutlined,
  CalendarOutlined,
  // HomeOutlined,
  // UserOutlined,
  // FormOutlined,
  // TableOutlined,
  // LineChartOutlined, CompassOutlined, LayoutOutlined, BlockOutlined,
} from '@ant-design/icons';
//import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItemAdmin {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItemAdmin[];
  icon?: React.ReactNode;
}

export const sidebarNavigationAdmin: SidebarNavigationItemAdmin[] = [
  // {
  //   title: 'common.nft-dashboard',
  //   key: 'nft-dashboard',
  //   // TODO use path variable
  //   url: '/dashboard',
  //   icon: <NftIcon />,
  // },
  {
    title: 'common.hajlink-dashboard',
    key: 'hajlink-dashboard',
    url: '/dashboard',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Workshop',
    key: 'workshop',
    url: '/workshop/list',
    icon: <CalendarOutlined />,
  },
  {
    title: 'common.agent',
    key: 'agent',
    url: '/agent/list',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Setup',
    key: 'setup',
    icon: <AppstoreAddOutlined />,
    children: [
      {
        title: 'common.service',
        key: 'service',
        url: '/service/list',
      },
      {
        title: 'common.activity',
        key: 'activity',
        url: '/activity/list',
      },
      {
        title: 'common.category',
        key: 'category',
        url: '/category/list',
      },
    ],
  },
  {
    title: 'Package Setup',
    key: 'pkgsetup',
    icon: <ApartmentOutlined />,
    children: [
      {
        title: 'common.package',
        key: 'package',
        url: '/package/list',
      },
      {
        title: 'Hotels',
        key: 'hotellist',
        url: '/hotel/list',
      },
      {
        title: 'Rooms',
        key: 'roomlist',
        url: '/room/list',
      },
      {
        title: 'Flights',
        key: 'flights',
        url: '/flight/list',
      },
      {
        title: 'Buses',
        key: 'buses',
        url: '/bus/list',
      },
    ],
  },
  {
    title: 'CIF',
    key: 'customer',
    icon: <TeamOutlined />,
    children: [
      {
        title: 'Customers',
        key: 'customerlist',
        url: '/customer/list',
      },
      {
        title: 'common.pregistration',
        key: 'pregistration',
        url: '/pre_registration/list',
      },
      {
        title: 'common.registration',
        key: 'registration',
        url: '/registration/list',
      },
      {
        title: 'Customer Packages',
        key: 'mpackagecustomer',
        url: '/m_customer_package/list',
      },
      {
        title: 'Customer Flights',
        key: 'acustomerflight',
        url: '/assign_customer_flight/list',
      },
      {
        title: 'Customer Rooms',
        key: 'acustomerroom',
        url: '/assign_customer_room/list',
      },
      {
        title: 'Customer Buses',
        key: 'acustomerbus',
        url: '/assign_customer_bus/list',
      },
      {
        title: ' Customer Documents',
        key: 'documents',
        url: 'customer/doc/list',
      },
      {
        title: ' Customer Activities',
        key: 'activities',
        url: 'customer/doc/list',
      },
      {
        title: 'common.customersearch',
        key: 'customersearch',
        url: '/customer/search',
      },
    ],
  },
  {
    title: 'Finance',
    key: 'finance',
    icon: <BankOutlined />,
    children: [
      {
        title: 'common.payments',
        key: 'payments',
        url: '/customer/payment/list',
      },
      {
        title: 'Expense',
        key: 'expenditure',
        url: '/expense/list',
      },
    ],
  },
  /* {
    title: 'common.ppreference',
    key: 'ppreference',
    url: '/package_preferences/list',
    icon: <PushpinOutlined />,
  }, */
  {
    title: 'Reports',
    key: 'reports',
    icon: <FileDoneOutlined />,
    children: [
      {
        title: 'Customers List' + '\u00b0',
        key: 'customerReport',
        url: '/customer/details/report',
      },
      {
        title: 'Customers by Pre-Registration',
        key: 'pregistrationreport',
        url: '/customer/pregistration/report',
      },
      {
        title: 'Customers by Registration',
        key: 'registrationreport',
        url: '/customer/registration/report',
      },
      {
        title: 'Customers by Package',
        key: 'custpackagereport',
        url: '/customer/package/report',
      },
      {
        title: 'Customers by Hotel',
        key: 'hotelreport',
        url: '/customer/hotel/report',
      },
      {
        title: 'Customers by Bus',
        key: 'busreport',
        url: '/customer/bus/report',
      },
      {
        title: 'Customers by Flight',
        key: 'flightreport',
        url: '/customer/flight/report',
      },
      {
        title: 'Room Sticker',
        key: 'roomstickerreport',
        url: '/customer/roomsticker/report',
      },
      {
        title: 'Passport Sticker',
        key: 'passportstickerreport',
        url: '/customer/passportsticker/report',
      },
      {
        title: 'Customers by Document',
        key: 'custmissingdocs',
        url: '/customer/document/report',
      },
      {
        title: 'Uploaded Documents',
        key: 'customerdocs',
        url: '/customer/uploaded/document/report',
      },
      {
        title: 'Package Report',
        key: 'packagereport',
        url: '/setup/package/report',
      },
      {
        title: 'Customer ID Card Generation',
        key: 'idcard',
        url: 'customer/generate/idcard',
      },
    ],
  },
  /*{
    title: 'common.apps',
    key: 'apps',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'common.feed',
        key: 'feed',
        url: '/apps/feed',
      },
    ],
  },
  {
    title: 'common.authPages',
    key: 'auth',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.login',
        key: 'login',
        url: '/auth/login',
      },
      {
        title: 'common.signUp',
        key: 'singUp',
        url: '/auth/sign-up',
      },
      {
        title: 'common.lock',
        key: 'lock',
        url: '/auth/lock',
      },
      {
        title: 'common.forgotPass',
        key: 'forgotPass',
        url: '/auth/forgot-password',
      },
      {
        title: 'common.securityCode',
        key: 'securityCode',
        url: '/auth/security-code',
      },
      {
        title: 'common.newPassword',
        key: 'newPass',
        url: '/auth/new-password',
      },
    ],
  },
  {
    title: 'common.forms',
    key: 'forms',
    icon: <FormOutlined />,
    children: [
      {
        title: 'common.advancedForms',
        key: 'advanced-forms',
        url: '/forms/advanced-forms',
      },
    ],
  },
  {
    title: 'common.dataTables',
    key: 'dataTables',
    url: '/data-tables',
    icon: <TableOutlined />,
  },
  {
    title: 'common.charts',
    key: 'charts',
    url: '/charts',
    icon: <LineChartOutlined />,
  },
  {
    title: 'common.maps',
    key: 'maps',
    icon: <CompassOutlined />,
    children: [
      {
        title: 'common.googleMap',
        key: 'google-maps',
        url: '/maps/google-maps',
      },
      {
        title: 'common.leafletMap',
        key: 'leaflet-maps',
        url: '/maps/leaflet-maps',
      },
      {
        title: 'common.reactSimpleMaps',
        key: 'react-simple-maps',
        url: '/maps/react-simple-maps',
      },
      {
        title: 'common.pigeonMaps',
        key: 'pigeon-maps',
        url: '/maps/pigeon-maps',
      },
    ],
  },
  {
    title: 'common.pages',
    key: 'pages',
    icon: <LayoutOutlined />,
    children: [
      {
        title: 'common.profilePage',
        key: 'profile',
        url: '/profile',
      },
      {
        title: 'common.serverError',
        key: 'serverError',
        url: '/server-error',
      },
      {
        title: 'common.clientError',
        key: '404Error',
        url: '/404',
      },
    ],
  },
  {
    title: 'common.ui',
    key: 'ui',
    icon: <BlockOutlined />,
    children: [
      {
        title: 'common.alert',
        key: 'alert',
        url: '/ui-components/alert',
      },
      {
        title: 'common.avatar',
        key: 'avatar',
        url: '/ui-components/avatar',
      },
      {
        title: 'common.autocomplete',
        key: 'auto-complete',
        url: '/ui-components/auto-complete',
      },
      {
        title: 'common.badge',
        key: 'badge',
        url: '/ui-components/badge',
      },
      {
        title: 'common.breadcrumbs',
        key: 'breadcrumbs',
        url: '/ui-components/breadcrumbs',
      },
      {
        title: 'common.button',
        key: 'button',
        url: '/ui-components/button',
      },
      {
        title: 'common.checkbox',
        key: 'checkbox',
        url: '/ui-components/checkbox',
      },
      {
        title: 'common.collapse',
        key: 'collapse',
        url: '/ui-components/collapse',
      },
      {
        title: 'common.dateTimePicker',
        key: 'dateTimePicker',
        url: '/ui-components/date-time-picker',
      },
      {
        title: 'common.dropdown',
        key: 'dropdown',
        url: '/ui-components/dropdown',
      },
      {
        title: 'common.input',
        key: 'input',
        url: '/ui-components/input',
      },
      {
        title: 'common.modal',
        key: 'modal',
        url: '/ui-components/modal',
      },
      {
        title: 'common.notification',
        key: 'notification',
        url: '/ui-components/notification',
      },
      {
        title: 'common.pagination',
        key: 'pagination',
        url: '/ui-components/pagination',
      },
      {
        title: 'common.popconfirm',
        key: 'popconfirm',
        url: '/ui-components/popconfirm',
      },
      {
        title: 'common.popover',
        key: 'popover',
        url: '/ui-components/popover',
      },
      {
        title: 'common.progress',
        key: 'progress',
        url: '/ui-components/progress',
      },
      {
        title: 'common.radio',
        key: 'radio',
        url: '/ui-components/radio',
      },
      {
        title: 'common.rate',
        key: 'rate',
        url: '/ui-components/rate',
      },
      {
        title: 'common.result',
        key: 'result',
        url: '/ui-components/result',
      },
      {
        title: 'common.select',
        key: 'select',
        url: '/ui-components/select',
      },
      {
        title: 'common.skeleton',
        key: 'skeleton',
        url: '/ui-components/skeleton',
      },
      {
        title: 'common.spinner',
        key: 'spinner',
        url: '/ui-components/spinner',
      },
      {
        title: 'common.steps',
        key: 'steps',
        url: '/ui-components/steps',
      },
      {
        title: 'common.switch',
        key: 'switch',
        url: '/ui-components/switch',
      },
      {
        title: 'common.tabs',
        key: 'tabs',
        url: '/ui-components/tabs',
      },
      {
        title: 'common.upload',
        key: 'upload',
        url: '/ui-components/upload',
      },
    ],
  },*/
];
